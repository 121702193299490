import { useState } from "react";

const Contact = () => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [textarea, setTextarea] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("http://localhost:8000/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ firstname, lastname, email, textarea }),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      setLastname("");
      setFirstname("");
      setEmail("");
      setTextarea("");
    } catch (error) {
      console.error("theres an error", error);
    }
  };
  return (
    <div className="contact">
      <form
        onSubmit={handleSubmit}
        className=" form  m-10 mx-32 rounded-md flex-wrap sm:border-2 md:border-2 md:shadow-lg sm:border-transparent md:shadow-secondary-200 md:border-secondary-200"
      >
        <h2 className="font-bold text-center mt-10 text-xl"> Contact Us</h2>
        <div className=" justify-center flex flex-wrap pt-20 pb-10  ">
          <div className="form-list mx-28 ">
            <div>
              <label className=" font-semibold text- " htmlFor="name">
                First Name
              </label>
              <input
                className="border mb-6 pl-2 pr-10 py-3 rounded-md hover:border-none "
                type="text"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
                placeholder=""
              />

              <label className=" font-semibold text-lg " htmlFor="">
                Last Name
              </label>
              <input
                className="border mb-6 pl-2 pr-10 py-3 rounded-md hover:border-none"
                type="text"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
                placeholder=""
              />
              <label className=" font-semibold text-lg " htmlFor="">
                Email
              </label>
              <input
                className="border mb-6 pl-2 pr-10 py-3 rounded-md hover:border-none"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder=""
              />

              <label className="block  font-semibold text-lg" htmlFor="">
                Gender
              </label>
              <select
                className="border  mb-6 pl-2 pr-10 py-3 hover:border-none rounded-md  block"
                type="email"
                placeholder="Email"
                name="hechoosello"
                id=""
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="others">Others</option>
              </select>
            </div>
          </div>

          <div className="text mx-28 text-center  ">
            <div className="">
              <label className=" font-semibold text-md  " htmlFor="">
                Text Here
              </label>
              <br />
              <textarea
                className="border mb-6 pl-4 pr-28 pb-52 pt-4 hover:shadow rounded-md text-start"
                type="text"
                value={textarea}
                onChange={(event) => setTextarea(event.target.value)}
                name=""
                id=""
              ></textarea>
            </div>
          </div>
        </div>
        <div className="button-submit flex items-center justify-center my-4 ">
          <button
            className=" button-submit border-4 rounded-md px-6 py-2 hover:shadow-md hover:shadow-primary-300 "
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>

      <div
        className="text- m-10 text-lg  flex flex-wrap justify-center items-center
      "
      >
        <strong className="">Contact Us</strong>

        <p className="my-4">
          {" "}
          <strong>📍Location:</strong> Shop S7, 2nd Floor, Lekki Town Square
          Mall, 8B The Providence street, Lekki Phase 1 Lagos, Nigeria{" "}
        </p>
        <a href="">
          <strong>📞 Call us at:</strong> +2349169900555
        </a>

        <a href="" className="my-4">
          <strong>📱 WhatsApp:</strong> +2349169900888
        </a>
        <a href="">
          <strong>👻 Snapchat:</strong> asloriginals
        </a>
        <a href="" className="my-4">
          <strong>📌 Instagram:</strong> asl_originals
        </a>
      </div>
    </div>
  );
};

export default Contact;
