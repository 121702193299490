// import food7 from "./food7.png";
// import img7 from "./img7.webp";

import { Link } from "react-router-dom";

// import img8 from "./img8.jpeg";
const Home = () => {
  return (
    <div className="Home relative ">
      <div className="info bg-[url('./img8.jpeg')] bg-right-top  top-1  p-16 m-4 shadow-lg shadow-gray-500  ">
        <div className="info-text">
          <h2
            className=" drop-shadow-lg text-emerald-400 
          shadow-gray-700 lg:text-6xl font-bold text-4xl "
          >
            Get ready <br /> for a revolution <br /> in style.
          </h2>
          <button
            className="hover:transition-shadow hover:scale-125 bg-emerald-300 border-1 border-gray-500 rounded-lg lg:px-20 px-4 lg:py-3 py-1 
          lg:text-2xl font-semibold lg:mt-10 mt-2 hover:shadow-lg hover:shadow-gray-600 hover:text-gray-500 hover:font-semibold  hover:bg-white text-white"
          >
            <Link to="/About"> Coming Soon</Link>
          </button>
        </div>
        <div className="info-img">
          <img src={""} alt="" />
        </div>
      </div>
      <div className="message text-center text-md font-semibold mx-20 my-10 text-wrap ">
        <p>
        Welcome to ASL ORIGINALS, where outstanding quality and affordability come together in our business attire collection. We're dedicated to providing a wide range of clothing that meets the needs of professionals in all industries. Our commitment to excellence ensures that every piece is meticulously crafted with the finest materials and attention to detail.{" "}
        </p>
      </div>
    </div>
  );
};

export default Home;
