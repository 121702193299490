import { Link } from "react-router-dom";
import asllogo1 from "./asllogo1.png";

const Navbar = () => {
  const handleClick = () => {
    const sidebar = document.getElementById("sidebar");
    if (sidebar.classList.contains("hidden")) {
      sidebar.classList.remove("hidden");
      sidebar.classList.add("visible");
    } else {
      sidebar.classList.remove("visible");
      sidebar.classList.add("hidden");
    }
    // alert("i got clicked");
  };

  return (
    <div className="navbar p-0 pb-0 flex justify-between shadow-md shadow-primary-300 border-b-1 border-black mb-6 items-center  ">
      <div className="logo text-xl  font-bold ">
        <Link to="/">
          <img className="w-28 h-26 ml-2" src={asllogo1} alt="" />
        </Link>
      </div>
      <div
        id="sidebar"
        className=" sm:block hidden menu mr-8 font-semibold text-lg py-2"
      >
        <Link
          className=" m-2  hover:border-b-2 hover:border-b-pink-500 "
          to="/"
        >
          Home
        </Link>

        <a
          className=" m-2 hover:border-b-2 hover:border-b-pink-500"
          href="/about"
        >
          About
        </a>
        <a
          className="m-2 hover:border-b-2 hover:border-b-pink-500"
          href="/contact"
        >
          Contact
        </a>
      </div>
      <div>
        <button onClick={handleClick}>
          <svg
            id="sideba"
            className="ulist visible sm:hidden w-10 h-12 mr-8"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
          </svg>
        </button>
        {/* <ul id="sidebar" className="visible">
            <li> home</li>
            <li> about</li>
            <li> contact</li>
          </ul> */}
      </div>
    </div>
  );
};

export default Navbar;
