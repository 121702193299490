import food8 from "./food8.png";
import img5 from "./img5.jpg";
import img6 from "./img6.jpg";
const Cards = () => {
  return (
    <div className="cards justify-center flex items-center">
      <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6 sm:grid-cols-2 grid-cols-1  md:gap-6 m-8 rounded-md ">
        <div className="card">
          <img className="" src="./images/img21.jpeg" alt="" />
          <p className="m-8 break-words">
            Coming Soon!!!
          </p>
        </div>
        <div className="card">
          <img className="" src="./images/img23.jpeg" alt="" />
          <p className="m-8">
            Coming Soon!!!
          </p>
        </div>
        <div className="card">
          <img src="images/img16.jpeg" alt="" />
          <p className="m-8">
            Coming Soon!!!
          </p>
        </div>
        <div className="card">
          <img src="./images/img22.jpeg" alt="" />
          <p className="m-8">
            Coming Soon!!!
          </p>
        </div>
        <div className="card">
          <img src="./images/img1.jpeg" alt="" />
          <p className="m-8">
            Coming Soon!!!
          </p>
        </div>
        <div className="card">
          <img src="./images/img3.jpeg" alt="" />
          <p className="m-8">
            Coming Soon!!!
          </p>
        </div>
        <div className="card">
          <img src="./images/img4.jpeg" alt="" />
          <p className="m-8">
            Coming Soon!!!
          </p>
        </div>
        <div className="card">
          <img src="./images/img5.jpeg" alt="" />
          <p className="m-8">
            Coming Soon!!!
          </p>
        </div>
        <div className="card">
          <img src="./images/img6.jpeg" alt="" />
          <p className="m-8">
            Coming Soon!!!
          </p>
        </div>
        <div className="card">
          <img src="./images/img7.jpeg" alt="" />
          <p className="m-8">
            Coming Soon!!!
          </p>
        </div>
        <div className="card">
          <img src="./images/img10.jpeg" alt="" />
          <p className="m-8">
            Coming Soon!!!
          </p>
        </div>
        <div className="card">
          <img src="./images/img8.jpeg" alt="" />
          <p className="m-8">
            Coming Soon!!!
          </p>
        </div>
        <div className="card">
          <img src="./images/img9.jpeg" alt="" />
          <p className="m-8">
            Coming Soon!!!
          </p>
        </div>
        <div className="card">
          <img src="./images/img11.jpeg" alt="" />
          <p className="m-8">
            Coming Soon!!!
          </p>
        </div>
        <div className="card">
          <img src="./images/img12.jpeg" alt="" />
          <p className="m-8">
            Coming Soon!!!
          </p>
        </div>
        <div className="card">
          <img src="./images/img13.jpeg" alt="" />
          <p className="m-8">
            Coming Soon!!!
          </p>
        </div>
        <div className="card">
          <img src="./images/img14.jpeg" alt="" />
          <p className="m-8">
            Coming Soon!!!
          </p>
        </div>
        <div className="card">
          <img src="./images/img15.jpeg" alt="" />
          <p className="m-8">
            Coming Soon!!!
          </p>
        </div>
        <div className="card">
          <img src="./images/img2.jpeg" alt="" />
          <p className="m-8">
            Coming Soon!!!
          </p>
        </div>
        <div className="card">
          <img src="./images/img17.jpeg" alt="" />
          <p className="m-8">
            Coming Soon!!!
          </p>
        </div>
        {/* <div className="card">
          <img src="./images/img23.jpeg" alt="" />
          <p className="m-8">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam
            perferendis harum, veniam cumque quaerat ex!
          </p>
        </div>
        <div className="card">
          <img src="./images/img24.jpeg" alt="" />
          <p className="m-8">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam
            perferendis harum, veniam cumque quaerat ex!
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default Cards;
