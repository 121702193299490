import { useState } from "react";
import { Link } from "react-router-dom";
import img9 from "./img9.jpeg";
import img10 from "./img10.jpg";

const About = () => {
  return (
    <div className="about text-center">
      <h1 className="font-bold text-4xl my-6 drop-shadow-lg"> ASL ORIGINALS</h1>
      <p className="px-6 text-lg leading-relaxed tracking-wide">
        Welcome to ASL ORIGINALS, where outstanding quality and affordability
        come together in our business attire collection. We're dedicated to
        providing a wide range of clothing that meets the needs of professionals
        in all industries. Our commitment to excellence ensures that every piece
        is meticulously crafted with the finest materials and attention to
        detail. At ASL ORIGINALS, your satisfaction is our top priority. We know
        how important it is to present yourself with confidence and style in the
        professional world. That's why our garments are designed to be both
        stylish and durable, all while being budget-friendly. Our goal is to
        make high-quality business clothing accessible to everyone, offering you
        the best value for your money. We take pride in the trust our customers
        place in us and strive to exceed their expectations with every purchase.
        Our collections are tailored to provide the perfect blend of comfort,
        style, and practicality, ensuring you always look and feel your best.
        Discover why so many professionals rely on ASL ORIGINALS for their
        business attire needs and join our community of satisfied clients who
        appreciate quality and affordability. Experience the difference with ASL
        ORIGINALS where your satisfaction is our promise and excellence is our
        standard.
      </p>
      <div className="profile-img flex justify-center my-12">
        <svg
          className="w-20 h-20 "
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
        >
          <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
        </svg>
      </div>

      <div className="leading-relaxed tracking-wide">
        <h2 className="text-lg font-bold my-4">Why Choose Us</h2>
        <p className="px-6 text font-semibold">
          At ASL ORIGINALS, we offer luxury apparel that shows off your unique
          style. Here’s why people love us:
          <br />
          <strong>1. Top Quality:</strong> We offer the best materials. Our
          apparel is made to last and feel amazing.
          <br />
          <strong>2. Elegant Design:</strong> Our designers create stylish looks
          that mix classic and modern trends. Each piece is unique and classy.
          <br />
          <strong>3. Eco-Friendly:</strong> We care about the planet. We use
          eco-friendly materials and fair practices.
          <br />
          <strong>4. Great Service</strong>: We make shopping easy with
          excellent customer service. Your satisfaction is our highest priority.
          <br />
          <strong>5. Inclusive Luxury:</strong> We offer a wide range of styles
          and sizes for everyone. Our apparel is perfect for any occasion, from
          formal events to relaxed weekends.
          <br />
          <strong>6. Fashion Community:</strong> We are more than just a
          clothing brand. We inspire and get inspired by our customers.
          <br />
          Choose ASL ORIGINALS for top quality, elegant style, and a brand that
          cares. Join us and see why people love our luxury apparel.
        </p>

        <h4 className="text-xl font-bold mt-4">
          …..where luxury rises and brilliance shines…..
        </h4>
      </div>

      <Link to="/items">
        <h2 className=" my-10 bg-slate-200 font-semibold text-xl hover:scale-125 hover:text-secondary-100 ">
          Learn More
        </h2>
      </Link>
    </div>
  );
};

export default About;
