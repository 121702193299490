import Home from "./home";
import Navbar from "./navbar";
import Cards from "./cards";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./about";
import Contact from "./contact";
import Items from "./items";
import Change from "./change";
import Footer from "./footer";

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        {/* <Routes>
          <Route path="" element={<Navbar />}></Route>
        </Routes> */}

        <Routes>
          <Route path="/" element={<Home />}></Route>
        </Routes>
        <Routes>
          <Route path="/" element={<Cards />}></Route>
        </Routes>
        <Routes>
          <Route path="/about" element={<About />}></Route>
        </Routes>
        <Routes>
          <Route path="/contact" element={<Contact />}></Route>
        </Routes>
        <Routes>
          <Route path="/items" element={<Items />}></Route>
        </Routes>
        <Footer />
       
      </div>
    </Router>
  );
}

export default App;
