import { useEffect, useState } from "react";
import img9 from "./img9.jpeg";
import img10 from "./img10.jpg";
import ItemList from "./itemList";

const Items = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch("http://localhost:8000/data")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setItems(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching data", error);
      });
  }, []);

  // const [items, setItems] = useState([

  // ]);

  return (
    <div className="items-list flex justify-center">
      <ItemList items={items} />
    </div>
  );
};

export default Items;
